.status-table-right,
.status-table-left {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.status-table-left {
    margin-right: 0 !important;
}

// .status-table-right {
//     margin-left: 0 !important;
// }

.status-submit-btn {
    height: 100%;
    align-items: baseline !important;
    max-width: 100% !important;
}