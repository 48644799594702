@use '@carbon/colors';

.faq-container {
    background-color: colors.$white;
    padding: 2rem;
}

.faq-title {
    margin: 0.5rem 0;
}

.academy_box {
    background: rgb(99,204,244);
    background: -moz-linear-gradient(90deg, rgba(99,204,244,1) 0%, rgba(17,51,187,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(99,204,244,1) 0%, rgba(17,51,187,1) 100%);
    background: linear-gradient(90deg, rgba(99,204,244,1) 0%, rgba(17,51,187,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#63ccf4",endColorstr="#1133bb",GradientType=1);
    padding: 2rem;
    display: grid;
    grid-template-rows: repeat(3, 3fr);
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    grid-auto-flow: column dense;
    align-content: start;
}

.shortVideoBox {
    padding: 2rem;
}
  
  .video-container video {
    width: 100%;
    height: auto;
    display: block;
    
  }
  
  .video-description {
    padding: 20px;
    text-align: center;
  }
  
  .video-button {
    display: block;
    width: 200px;
    margin: 20px auto;
    padding: 10px 20px;
    background-color:blue;
    color: #fff;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 5px;
  }
  
  .video-button:hover {
    background-color: #0056b3; /* Adjust to your desired hover color */
  }
  

.videoColumn{
    padding: 2rem;
}

.videoDiv{
float: right;
width: 50%;
min-height: 400px;
border-radius: 25px;
}

#academyH1 {
    font-size: 25px;
    letter-spacing: 2px;
    word-spacing: 2px;
    color: #FFFFFF;
    font-weight: 700;
    text-transform: capitalize;
    width: 40%;
    float: left;
    padding: 1rem;
    padding-top: 10%;
    }

#academyText {
        font-size: 14px;
        letter-spacing: 2px;
        word-spacing: 2px;
        color: #FFFFFF;
        font-weight: 500;
        float: left;
        width: 40%;
        padding: 1rem;
        }

.status-submit-btn {
        height: 100%;
        align-items: baseline !important;
        max-width: 100% !important;
        }